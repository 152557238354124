import React from 'react';
import logo from './Klumba2.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1 id="klumba">KLUMBA</h1>
          <h2 id="fma">FMA Iceland</h2>
          <p id="pos">Ólafsbraut 80, 355 Ólafsvík</p>
        </div>
      </header>
      <body>
        <div>
          <h2>Contact</h2>
          <p><a className="contact" href="tel:+3544833700">+354 483 3700</a></p>
          <p><a className="contact" href="mailto: bokhald@klumba.is">bokhald@klumba.is</a> (invoices)</p>
          <p id="secondEmail"><a className="contact" href="mailto: klumba@klumba.is">klumba@klumba.is</a> (production)</p>
        </div>
      </body>
    </div>
  );
}

export default App;
